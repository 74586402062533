

























































import Vue from 'vue'
import { Status } from "../../model/entity/table_session";
import { TranslateServiceBuilder } from '../../services/Translate/translateServiceBuilder';

const translate = TranslateServiceBuilder.Instance()._;

export default Vue.component('SHTakeoutCard', {
  components: {
    QTranslate: () => import("../../components/elements/QTranslate.vue"),
  },
  props: {
    item: {
      type: Object,
      required: true,
    },
    payment: {
      type: Object,
      required: true,
    },
  },
  filters: {
      dateFormat1: function (value) {
          if (value === undefined || value === null) {
              return "";
          } else {
              let twoDigit = function(value) {
                return ('0' + value).slice(-2);
              };
              let date = new Date(value);
              let hour = date.getHours();
              let minute = twoDigit(date.getMinutes());
              return hour + translate('hall-0163', '時') + minute + translate('hall-0164', '分') ;
          }
      },
      dateFormat2: function (value) {
          if (value === undefined || value === null) {
              return "";
          } else {
              let twoDigit = function(value) {
                return ('0' + value).slice(-2);
              };
              let date = new Date(value);
              let hour = date.getHours();
              let minute = twoDigit(date.getMinutes());
              return hour + ':' + minute;
          }
      },
      numberFormat: function (number) {
          if (typeof number !== "number") {
              return number;
          }
          return number.toLocaleString();
      },
      taxPrice: function (prices) {
          if (prices['tanka']['exclude'] !== null) {
              return prices['tanka']['exclude'];
          }
          return prices['tanka']['include'];
      },
      taxTotal: function (prices) {
          if (prices['total']['exclude'] !== null) {
              return prices['total']['exclude'];
          }
          return prices['total']['include'];
      },
  },
})
